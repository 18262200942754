.container {
  position: relative;
  user-select: none;
}

.arrows {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 36px;
}

.next_button {
  margin-left: auto;
}

.months {
  display: flex;
  gap: var(--unit_12);
}
