.wrapper {
  position: relative;
  user-select: none;
}

.container {
  margin: 0;
  padding: 0;
  display: flex;
  overflow: scroll hidden;
  list-style: none;
  scrollbar-width: none;
  cursor: pointer;
  pointer-events: auto;
  -ms-overflow-style: none;
  column-gap: var(--unit_4);
}

.container::-webkit-scrollbar {
  display: none;
}

.container li {
  flex: 0 0 calc((100% - var(--unit_4) * 3) / 4);
}
