.cities-tabs {
  margin-bottom: 48px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  overflow: hidden;
  height: 28px;
}

.cities-tabs button {
  margin-right: 8px;
  flex: 0 0 auto;
  height: 28px;
}

.popular-cities button:last-child {
  margin-right: 0;
}

.cities {
  margin: 0;
  padding: 0;
  display: grid;
  width: 100%;
  list-style: none;
  grid-auto-flow: column;
  grid-template-rows: repeat(8, auto);
  grid-column-gap: 40px;
}

.city {
  margin-bottom: 6px;
}

.city-button {
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
}

.city-button:hover {
  color: var(--control-main-primary-pressed);
}
