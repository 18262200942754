.c-geo {
  padding-top: 44px;
  overflow: hidden;
  min-width: 992px;
  background: #f4f4f4;
}

.c-geo-section {
  margin-bottom: 27px;
}

.c-geo-section-title {
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #121212;
}

.c-geo-section-items-collapsed .c-geo-section-items {
  margin-bottom: 8px;
  overflow: hidden;
  width: 100%;
  max-height: 116px;
}

.c-geo-section-toggle {
  position: relative;
  margin-top: 0;
  padding-bottom: 1px;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  color: #121212;
  border-bottom: 1px dotted hsla(0, 0%, 7%, 0.5);
  transition: all 0.15s;
}

.c-geo-section-toggle:hover {
  color: #121212;
  border-bottom: 1px dotted hsla(0, 0%, 7%, 1);
}

.c-geo-section-toggle-icon {
  position: absolute;
  top: 7px;
  right: -13px;
  width: 8px;
  height: 5px;
  background: url('./arrow-open.svg') no-repeat;
  background-size: 100%;
  transition: all 0.3s;
  transform: rotate(180deg);
}

.c-geo-section-items-collapsed .c-geo-section-toggle-icon {
  top: 8px;
  transform: rotate(0deg);
}

.c-geo-row::after {
  display: block;
  clear: both;
  content: '';
}

.c-geo-item {
  padding-bottom: 4px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-geo-link {
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
  color: #121212;
  border-bottom: 1px solid transparent;
}

.c-geo-link:hover {
  color: #121212;
  border-bottom-color: hsla(0, 0%, 7%, 0.3);
}

.c-link-default {
  min-height: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  border-bottom: 1px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s,
    background 0.3s;
}

.c-link-default:hover {
  color: #256ba5;
  border-bottom-color: #b6d3ed;
}
