.input {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  cursor: pointer;
  /* stylelint-disable-next-line */
  opacity: 0 !important;
}

.checkbox {
  cursor: pointer;
  user-select: none;
}

.common {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}

.common .box {
  position: relative;
  display: inline-flex;
  width: 16px;
  height: 16px;
  content: '';
  border: 1px solid #d0d3d9;
  border-radius: 14%;
  flex: 0 0 auto;
}

.common .box + .label {
  margin-left: 12px;
  white-space: nowrap;
}

.common .box .icon {
  position: absolute;
  inset: 0;
  margin: auto;
  display: none;
}

.icon svg {
  width: 10px;
  fill: white;
}

.common:hover .box {
  border-color: var(--decorative-theme-dark);
}

.common.checked .box {
  background: var(--accent-main-primary);
  border-color: var(--accent-main-primary);
}

.common.checked .box .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.common.checked:hover .box {
  background: #055de1;
  border-color: #055de1;
}

input:focus + .common .box,
.common:focus .box {
  box-shadow: 0 0 0 3px #8ec5f2;
}

.common.disabled .box {
  border-color: #e4e4e4;
}

.common.disabled .icon svg {
  fill: #c9c9c9;
}

.common.disabled.checked .box {
  background: #f3f3f3;
  border-color: #f3f3f3;
}

.common.disabled .label {
  color: #c9c9c9;
}
