.c-promo-block-image-content {
  position: relative;
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
}

.c-promo-block-image-content_sale {
  background-image: url('./img/buy_x.jpg');
}

.c-promo-block-image-content_rent {
  background-image: url('./img/rent-long_x.jpg');
}

.c-promo-block-image-content_newbuidings {
  background-image: url('./img/new_buildings_x.jpg');
}

.c-promo-block-image-content_daily-rent {
  background-image: url('./img/rent-day_x.jpg');
}

.c-promo-block-image-content_suburban {
  background-image: url('./img/cottage_x.jpg');
}

.c-promo-block-image-content_commercial {
  background-image: url('./img/commercial_x.jpg');
}

.c-promo-catalog-list {
  position: absolute;
  top: 12px;
  left: 15px;
}

.c-promo-catalog-list-item {
  margin-bottom: 8px;
  padding: 4px 10px 6px;
  display: block;
  font-size: 14px;
  font-weight: bold;
  background-color: white;
  border: 0;
  border-radius: 4px;
}
