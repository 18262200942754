.c-promo-container {
  padding: 16px 0 46px;
}

.c-promo-title {
  margin: 20px 0 16px;
  overflow: hidden;
  font-size: 26px;
  font-weight: bold;
  line-height: 33px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1199px) {
  .cg-col-promo-s-6 {
    width: calc(25% - 0.5rem);
  }
  .cg-col-promo-s-8 {
    width: calc(33.33333333333333% - 0.5rem); /* stylelint-disable-line */
  }
  .cg-col-promo-s-16 {
    width: calc(66.66666666666666% - 0.5rem); /* stylelint-disable-line */
  }
  .cg-col-promo-s-18 {
    width: calc(75% - 0.5rem);
  }
  .cg-col-promo-s-24 {
    width: calc(100% - 0.5rem);
  }
}

/* замена calc(20px - 0.5rem) для фикса верстки в ms edge */
/* при максимальной ширине колонки */
/* stylelint-disable-next-line */
:global(.cg-container-fluid-xs) {
  padding-right: 14px;
}
