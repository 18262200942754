.c-sup-small {
  font-size: 7px;
  line-height: 2;
  vertical-align: top;
}

.page-container {
  position: relative;
}

.c-ruble::before {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  content: ' \20BD';
}

.c-dollar::before {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  content: ' \0024';
}

.c-euro::before {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  content: ' \20AC';
}

/* filters */
.c-filters-content {
  padding: 0 16px;
}

.c-filters-inner,
.c-filters-brand {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1120px;
}

.c-filters-title {
  position: relative;
  margin: 0;
  min-height: 46px;
  font-size: 38px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

.c-filters-title-text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  line-height: 1.2;
}

.c-filters-form {
  margin: 26px 0 10px;
  display: -ms-flexbox; /* stylelint-disable-line */
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.c-filters-field {
  -ms-flex: 0 1 auto; /* stylelint-disable-line */
  flex: 0 1 auto;
  position: relative;
  background: white;
  border-right: 1px solid #e9e9e9;
  transition: background-color 0.3s;
}

.c-filterst-field_container-preloader {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 46px;
  background: white;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.c-filters-field:first-child,
.c-filters-field:first-child .c-several-filters-dropdown-container {
  border-radius: 4px 0 0 4px;
}

.c-filters-field:last-child,
.c-filters-field:last-child .c-several-filters-dropdown-container {
  border-radius: 0 4px 4px 0;
}

.c-filters-field > span {
  display: block;
}

.c-filters-field-operations {
  min-width: 125px;
}

.c-filters-field-property {
  min-width: 150px;
  max-width: 238px;
}

.c-filters-field-room {
  max-width: 226px;
}

.c-filters-field-year {
  max-width: 200px;
}

.c-filters-field-square {
  max-width: 100px;
}

.c-several-filters-field,
.c-several-filters-field-double,
.c-several-filters-field-part {
  display: -ms-flexbox; /* stylelint-disable-line */
  display: flex;
}

.c-several-filters-dropdown-container,
.c-several-filters-field-double,
.c-several-filters-field-part {
  -ms-flex: 0 1 auto; /* stylelint-disable-line */
  flex: 0 1 auto;
  position: relative;
}

.c-several-filters-dropdown-container-disabled:hover {
  background-color: transparent;
}

.c-several-filters-dropdown-container:hover,
.c-several-filters-field_disabled:hover .c-several-filters-dropdown-container {
  background-color: #e9f3fb;
}

.c-filters-field-price-text-input {
  margin: 8px 0 10px;
  padding: 0 14px;
  display: block;
  overflow: hidden;
  max-width: 105px;
  height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}

.c-filters-field-price-text-input::-ms-clear {
  display: none;
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
.c-filters-field-price-text-input::-webkit-input-placeholder {
  color: #7a7a7a;
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
.c-filters-field-price-text-input:-ms-input-placeholder {
  color: #7a7a7a;
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
.c-filters-field-price-text-input::-moz-placeholder {
  color: #7a7a7a;
}

.c-several-filters-dropdown-container:first-child > .c-filters-field-price-text-input {
  border-right: 1px solid #e4e4e4;
}

.c-several-filters-field-double .c-several-filters-dropdown-container:first-child > .c-filters-field-price-text-input {
  border-right: 0;
}

.c-filters-currency .c-filters-dropdown-button {
  font-size: 14px;
}

.c-filters-field-region {
  -ms-flex: 5 1 1px; /* stylelint-disable-line */
  flex: 5 1 1px;
  border: none;
}

.c-filters-field-region .c-filters-dropdown-button,
.c-filters-units .c-filters-dropdown-button {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-filters-field-button {
  -ms-flex: 0 0 100px; /* stylelint-disable-line */
  flex: 0 0 100px;
  background: none;
  border: none;
}

.c-filters-field-button:hover {
  background: none;
}

.c-filters-field-dropdown {
  float: left;
  width: 160px;
}

.c-filters-dropdown-button,
.c-filters-field-content {
  padding: 12px 14px 14px;
  overflow: hidden;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #121212 !important; /* stylelint-disable-line */
  background: none;
  border: none;
  outline: none;
}

.c-filters-dropdown-button:active,
.c-filters-field-content:active {
  transform: none;
}

.c-several-filters-dropdown-container-disabled .c-filters-dropdown-button {
  cursor: default;
  color: #7a7a7a !important; /* stylelint-disable-line */
}

.c-sup {
  position: relative;
  top: -0.5em;
  font-size: 8px;
  line-height: 2;
  vertical-align: top;
}

.c-filters-field-content-arrow {
  padding-right: 40px;
}

.c-filters-field-content-arrow:hover::after,
.c-filters-field-content-arrow::after {
  position: absolute;
  top: 50% !important; /* stylelint-disable-line */
  right: 16px;
  margin-top: -3px;
  display: block;
  width: 10px;
  height: 6px;
  content: '';
  background: url('./dropdown-arrow.svg') 50% 50% no-repeat !important; /* stylelint-disable-line */
  transition: transform 0.15s;
}

.c-filters-field-region-text-input {
  padding: 14px 40px 17px 14px;
  width: 100%;
  height: 50px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.c-filters-field-region-text-input::-ms-clear {
  display: none;
}

.c-filters-field-mute {
  color: #7a7a7a !important; /* stylelint-disable-line */
}

.c-filters-dropdown {
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 0;
  margin-top: 13px;
  background: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.c-filters-dropdown-hidden {
  display: none;
}

.c-filters-dropdown::before,
.c-filters-dropdown::after {
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
  pointer-events: none;
  content: ' ';
  border: solid transparent;
  border-color: transparent;
}

.c-filters-dropdown::before {
  left: 15px;
  border-width: 8px;
  border-bottom-color: #c9c9c9;
}

.c-filters-dropdown::after {
  left: 16px;
  border-width: 7px;
  border-bottom-color: white;
}

.c-filters-field-region .c-filters-dropdown {
  width: 100%;
}

.c-filters-operations-content-check,
.c-filters-operations-content {
  padding: 10px 0;
  max-height: inherit;
}

.c-filters-operations-check-lbl,
.c-filters-operations-content-check > div > div {
  padding: 5px 16px 7px;
  height: 32px;
}

.c-filters-field-region .c-filters-operations-content {
  max-height: 320px;
}

.c-filters-operations-radio-lbl {
  position: relative;
  padding: 5px 30px 7px;
  display: block;
  overflow: hidden;
  height: 32px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
}

.c-filters-radio-active {
  background: #e9f3fb;
}

.c-filters-operations-radio-lbl:hover {
  background: #e9f3fb;
}

.c-filters-radio-active::before {
  position: absolute;
  top: calc(50% - 5px);
  left: 11px;
  display: block;
  width: 14px;
  height: 10px;
  content: '';
}

.c-filters-property-content {
  padding: 30px 22px 18px 30px;
  width: 670px;
}

.c-filters-property-content--short {
  width: 490px;
}

.c-filters-property-row {
  margin-bottom: 18px;
}

.c-filters-property-row::after {
  display: block;
  clear: both;
  content: '';
}

.c-filters-property-row:last-child {
  margin-bottom: 0;
}

.c-filters-property-title {
  display: block;
  float: left;
  width: 120px;
  height: 28px;
  line-height: 1.75;
}

.c-filters-property-list {
  margin: 0;
  padding: 0;
  display: block;
  float: left;
  width: 495px;
  list-style: none;
}

.c-filters-property-list--short {
  width: 305px;
}

.c-filters-property-list-item {
  margin-bottom: 10px;
  float: left;
}

.c-filters-property-list-item:not(:last-child) {
  margin-right: 8px;
}

.c-filters-property-lbl {
  display: block;
}

.c-filters-property-radio {
  display: none;
}

.c-filters-property-radio-lbl {
  padding: 0 15px;
  display: block;
  height: 28px;
  line-height: 1.75;
  cursor: pointer;
  border: 1px solid #c9c9c9;
  border-radius: 14px;
  transition: 0.3s;
}

.c-filters-property-radio:checked + .c-filters-property-radio-lbl {
  color: white;
  background: #2b87db;
  border-color: #2b87db;
}

.c-filters-property-radio-lbl:hover {
  border-color: #7a7a7a;
}

.c-filters-property-radio:checked + .c-filters-property-radio-lbl:hover {
  background: #256ba5;
  border-color: #256ba5;
}

.c-filters-rooms {
  max-width: 205px;
}

.c-filters-rooms .c-filters-dropdown-button {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-filters-rooms-content {
  padding: 16px;
}

.c-filters-rooms-row {
  margin-bottom: 16px;
}

.c-filters-rooms-row:last-child {
  margin-bottom: 0;
}

.c-filters-rooms-label {
  position: relative;
  padding-left: 22px;
  display: block;
  line-height: 14px;
  cursor: pointer;
}

.c-filters-rooms-checkbox-text {
  display: block;
  white-space: nowrap;
}

.c-filters-area-content,
.c-filters-price-content,
.c-filters-price-with-params-content {
  padding: 20px 16px;
  white-space: nowrap;
}

.c-filters-area-content {
  font-size: 0;
}

.c-filters-area-content input {
  position: relative;
  padding: 2px 7px;
  display: inline-block;
  width: 90px;
  height: 28px;
  font-size: 14px;
  line-height: 22px;
  vertical-align: middle;
  outline: none;
}

.c-filters-area-content input:focus {
  border-color: black;
}

.c-filters-inputs-separator {
  display: inline-block;
  vertical-align: middle;
}

.c-filters-inline-switcher {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

.c-filters-inline-switcher-lbl {
  display: block;
  float: left;
}

.c-filters-inline-switcher-radio {
  display: none;
}

.c-filters-inline-switcher-radio-lbl {
  padding: 2px 11px;
  display: block;
  height: 28px;
  line-height: 22px;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: #c9c9c9 transparent #c9c9c9 #c9c9c9;
}

.c-filters-inline-switcher-lbl:first-child .c-filters-inline-switcher-radio-lbl {
  border-radius: 14px 0 0 14px;
}

.c-filters-inline-switcher-lbl:last-child .c-filters-inline-switcher-radio-lbl {
  border-right-color: #c9c9c9;
  border-radius: 0 14px 14px 0;
}

.c-filters-inline-switcher-radio:checked + .c-filters-inline-switcher-radio-lbl {
  color: white;
  background: #2b87db;
  border-color: #2b87db;
}

.c-filters-inline-switcher-radio-lbl sup {
  margin-top: -5px;
  display: inline-block;
}

.c-filters-price-switcher {
  margin-left: 16px;
}

.c-filters-price-with-params-content {
  width: 460px;
}

.c-filters-regions-dropdown {
  width: 100%;
}

.c-filters-regions-content {
  padding: 10px 0;
  overflow: auto;
  height: 322px;
}

.c-filters-regions-item {
  padding-left: 30px;
  display: block;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
}

.c-filters-regions-item:hover {
  background: #e9f3fb;
}

.c-filters-field-content-arrow.c-filters-dropdown-opened::after {
  transform: rotate(180deg);
}

.c-filters-form-buttons::after {
  display: block;
  clear: both;
  content: '';
}

.c-filters-field-button {
  margin-left: 10px;
  float: right;
  width: 114px;
  height: 50px;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  background: var(--accent-main-primary);
  border: none;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.3s;
}

.c-filters-field-button:hover {
  background: #055de1;
}

.c-filters-field-button:active {
  background: #004ec4;
}

.c-filters-field-map-button {
  display: flex;
  float: right;
  justify-content: center;
  align-items: center;
  width: 206px;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  cursor: pointer;
  color: #121212;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.c-filters-field-map-button span {
  position: relative;
  padding-left: 18px;
}

.c-filters-field-map-button span::before {
  position: absolute;
  top: 1px;
  left: -1px;
  bottom: 0;
  display: block;
  width: 16px;
  height: 16px;
  content: '';
  background: url('./geo-icon.svg') no-repeat;
}

.c-filters-field-map-button:hover {
  color: #2b87db;
  background-color: #f7f7f7;
}

.c_filters-suggest_input_field {
  position: relative;
  padding-right: 40px;
  padding-left: 16px;
  width: 100%;
  height: 50px;
  cursor: pointer;
  border: none;
}

.c_filters-suggest_input_field--small {
  height: 35px;
  cursor: text;
}

input.c_filters-suggest_input_field--small:hover {
  background-color: white;
}

.c_filters-suggest_input_wrapper {
  margin-top: 15px;
}

.c_filters-geo_input_label {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 4px 0 0 7px;
  width: 100%;
  font-size: 12px;
  line-height: 1.67;
  cursor: text;
  color: #7a7a7a;
}

div.c_filters-suggest_popup {
  width: 100%;
}

.c_filters-suggest_popup-content {
  overflow-y: auto;
  max-height: 320px;
}

.c_filters-suggest_input-drop_icon {
  position: absolute;
  right: 16px;
  display: block;
  width: 10px;
  height: 6px;
  content: '';
  top: 50% !important; /* stylelint-disable-line */
  margin-top: -3px;
  padding: 0;
  background: url('./dropdown-arrow.svg') 50% 50% no-repeat !important; /* stylelint-disable-line */
  border: none;
  outline: none;
  transition: transform 0.15s;
}

.c_filters-suggest_input-drop_icon--strict_filter {
  cursor: text;
}

.c_filters-suggest_input-drop_icon--simple_filter {
  cursor: pointer;
}

.c_filters-suggest_input-drop_icon.c_filters-suggest_input-drop_icon--opened {
  transform: rotate(180deg);
}

.c_filters-suggest_loader {
  margin: 0 auto;
  text-align: center;
}

.c_filters-suggest_input_field:hover {
  background-color: #e9f3fb;
}

.c_filters-suggested_message {
  margin: 5px 0 7px;
}

.c_filters-suggested_message_geo {
  margin: 10px 0 12px;
}

.c_filters-suggested_message_text {
  margin: 0 30px;
}

.c_filters-suggested_message_text--title {
  margin-bottom: 5px;
}

.c_filters-suggested_message_text--light {
  line-height: 1.29;
  color: var(--cb-color-gray-1);
}

.c_filters-suggested_delimiter {
  margin: 20px 0;
  height: 1px;
  background-color: var(--cb-color-gray-3);
  border: none;
}

.c_filters-suggested_items {
  position: relative;
  padding: 5px 30px 7px;
  cursor: pointer;
}

.c_filters-suggested_items--without_checked {
  padding: 5px 30px 7px 8px;
}

.c_filters-suggested_items--grouped {
  padding-left: 16px;
}

.c_filters-suggested_items--selected::before {
  position: absolute;
  top: calc(50% - 5px);
  left: 11px;
  display: block;
  width: 14px;
  height: 10px;
  content: '';
  background-image: url('./tick_big.svg');
}

.c_filters-suggested_items--focus {
  background-color: #e9f3fb;
}

.c_filters-suggest_container {
  padding: 10px 0;
  overflow-y: auto;
  width: 100%;
  max-height: 320px;
}

.c_filters-suggested-group-title {
  padding: 12px 0 2px 16px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7a7a7a;
}

.c_filters-suggested-items--desc {
  margin: 0;
  font-size: 14px;
  line-height: 1;
  color: #969696;
}

.c_filters-suggested-items--link {
  position: absolute;
  top: 6px;
  right: 6px;
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('./link.svg') 50% no-repeat;
  border-radius: 32px;
}

.c_filters-suggested-items--link:hover {
  background-color: #cce2f7;
}

/* MultiSuggestPopup */

.multi-popup {
  margin-top: 4px;
}

.title {
  padding-left: 33px;
  padding-bottom: 3px;
  overflow: hidden;
  width: 100%;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7a7a7a;
}

.address {
  overflow: hidden;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  white-space: nowrap;
  letter-spacing: normal;
  text-overflow: ellipsis;
  color: #7a7a7a;
}

.item {
  padding-right: 11px;
  padding-left: 33px;
  display: block;
  overflow: hidden;
  width: 100%;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  white-space: nowrap;
  text-decoration: none;
  letter-spacing: normal;
  text-overflow: ellipsis;
  color: #121212;
}

.item-selected {
  background-color: #eaf3fb;
}

.item-unavailable {
  color: #7a7a7a;
}

.container {
  padding: 11px 0;
  overflow-y: auto;
  width: 100%;
  max-height: 320px;
}

.group-container {
  position: relative;
  margin-bottom: 16px;
}

.group-container:last-child {
  margin-bottom: 0;
}

.addresses {
  position: absolute;
  top: -2px;
  left: 11px;
  width: 12px;
  height: 17px;
  background: url('./filters/icons/addresses.svg') center no-repeat;
  background-size: contain;
}

.cities {
  position: absolute;
  top: -2px;
  left: 11px;
  width: 16px;
  height: 16px;
  background: url('./filters/icons/cities.svg') center no-repeat;
  background-size: contain;
}

.districts {
  position: absolute;
  top: 0;
  left: 9px;
  width: 17px;
  height: 13px;
  background: url('./filters/icons/districts.svg') center no-repeat;
  background-size: contain;
}

.newbuildings {
  position: absolute;
  top: 0;
  left: 10px;
  width: 17px;
  height: 12px;
  background: url('./filters/icons/newbuildings.svg') center no-repeat;
  background-size: contain;
}

.undergrounds {
  position: absolute;
  top: 0;
  left: 9px;
  width: 16px;
  height: 11px;
  background: url('./filters/icons/underground.svg') center no-repeat;
  background-size: contain;
}

.railway {
  position: absolute;
  top: 0;
  left: 9px;
  width: 16px;
  height: 16px;
  background: url('./filters/icons/railway.svg') center no-repeat;
  background-size: contain;
}

.business {
  position: absolute;
  top: -2px;
  left: 9px;
  width: 15px;
  height: 15px;
  background: url('./filters/icons/business.svg') center no-repeat;
  background-size: contain;
}

.shop {
  position: absolute;
  top: 0;
  left: 9px;
  width: 16px;
  height: 15px;
  background: url('./filters/icons/shop.svg') center no-repeat;
  background-size: contain;
}

.villages {
  position: absolute;
  top: 0;
  left: 9px;
  width: 16px;
  height: 16px;
  background: url('./filters/icons/home.svg') center no-repeat;
  background-size: contain;
}

.roads {
  position: absolute;
  top: 0;
  left: 9px;
  width: 16px;
  height: 15px;
  background: url('./filters/icons/roads.svg') center no-repeat;
  background-size: contain;
}

.item-building {
  position: relative;
  padding-right: 42px;
  height: 48px;
}

.link {
  position: absolute;
  top: 7px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.link:hover {
  background: rgba(43, 135, 219, 0.1);
}

.link-icon {
  display: block;
  width: 12px;
  height: 12px;
  background: url('./filters/icons/building.svg') center no-repeat;
  background-size: contain;
}

.building-name {
  padding-top: 8px;
  overflow: hidden;
  width: 100%;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1199px) {
  .c-filters-field-room {
    max-width: 135px;
  }
  .c-filters-field-operations {
    min-width: 107px;
  }
  .c-filters-field-property {
    min-width: 118px;
    max-width: 170px;
  }
}

.c-filters-title_dobroshrift {
  height: 46px;
}

.c-dobroshrift {
  position: relative;
  display: inline-flex;
}

.c-dobroshrift-link {
  position: absolute;
  top: -24px;
  right: 4px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  text-decoration: none;
  color: white;
  border-bottom: 1px solid transparent;
  transition: border 0.3s;
}

.c-dobroshrift-link:hover {
  border-bottom-color: white;
}
