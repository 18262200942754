.c-selfad-single {
  padding: 20px;
  display: flex;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
}

.c-selfad-single-content {
  padding-left: 120px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: url('./img/complex.svg') no-repeat;
}

@media screen and (max-width: 1024px) {
  .c-selfad-single {
    margin-bottom: 43px;
  }
}
