.container {
  position: relative;
  margin: 0 auto;
  display: flex;
  max-width: 1109px;
  height: 60px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.wrapper {
  display: flex;
  width: 100%;
}

.buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.mortgage_building_type {
  width: 22%;
}

.mortgage_realty_price {
  width: 22%;
}

.mortgage_down_payment {
  width: 20%;
}

.mortgage_term {
  width: 13%;
}

.mortgage_region {
  width: 23%;
}
