.c-popular-block {
  position: relative;
  display: -ms-flexbox; /* stylelint-disable-line */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 340px;
  background: white;
  border: 1px solid #e4e4e4;
  transition: border-color 0.3s;
}

.c-popular-block-jk {
  padding-bottom: 51px;
  min-height: 400px;
}

.c-popular-block:hover {
  border-color: #c9c9c9;
}

.c-popular-info {
  padding: 15px 20px 35px;
  color: black;
}

.c-popular-info-jk {
  min-height: 147px;
}

.c-popular-price,
.c-popular-tec-info,
.metro-name,
.metro-time,
.address {
  display: block;
}

.c-popular-price {
  margin-bottom: 1px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.c-popular-tec-info {
  margin-bottom: 9px;
  font-weight: bold;
  line-height: 18px;
  text-decoration: none;
  color: black;
}

.c-popular-how-to {
  min-height: 340px;
  border: 2px dashed #e4e4e4;
  display: -ms-flexbox; /* stylelint-disable-line */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.c-popular-how-to-icon {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  background: linear-gradient(to top right, rgba(255, 82, 78, 1) 0%, rgba(254, 131, 2, 1) 100%);
  border-radius: 50%;
}

.c-popular-how-to-icon::before,
.c-popular-how-to-icon::after {
  position: absolute;
  display: block;
  content: '';
}

.c-popular-how-to-icon::before {
  top: 12px;
  left: 16px;
  border-left: 14px dashed transparent;
  border-right: 14px dashed transparent;
  border-bottom: 25px solid white;
}

.c-popular-how-to-icon::after {
  top: 35px;
  left: 23px;
  width: 14px;
  height: 13px;
  background: white;
}

.c-popular-how-to-title {
  margin-top: 24px;
  font-size: 18px;
  font-weight: bold;
}

.c-popular-how-to-text {
  margin-top: 12px;
  text-align: center;
}

.c-popular-how-to-btn {
  margin-top: 15px;
  padding: 0 16px;
  box-sizing: border-box;
  height: 28px;
  font-weight: bold;
  line-height: 26px;
  border: 1px solid #8fbde7;
  border-radius: 14px;
  transition:
    color 0.3s,
    border-color 0.3s;
}

.c-popular-how-to-btn:hover {
  color: #256ba5;
  border-color: #2b87db;
}

.c-popular-discount {
  position: absolute;
  top: 17px;
  left: 0;
  padding: 0 10px;
  max-width: 95%;
  height: 24px;
  line-height: 1.71;
  color: white;
  background: rgba(255, 80, 80, 0.7);
  border-radius: 2px 0 0 2px;
}

.c-popular-discount::before {
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  content: '';
  border-width: 12px 10px 12px 0;
  border-style: solid;
  border-color: transparent transparent rgba(255, 80, 80, 0.7);
}

.c-popular-discount::after {
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  content: '';
  border-width: 12px 10px 0 0;
  border-style: solid;
  border-color: rgba(255, 80, 80, 0.7) transparent transparent;
}

.c-popular-discount-content {
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-popular-top {
  margin-bottom: 9px;
  display: block;
}

.c-popular-block-title {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
}

.c-popular-block-title-text {
  margin-right: 7px;
  text-decoration: none;
  color: black;
}

.c-popular-block-title-class {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  color: #7a7a7a;
}

.c-popular-price-range {
  margin-top: 4px;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
}

.c-popular-developer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 51px;
  font-size: 12px;
  line-height: 48px;
  color: #7a7a7a;
  border-top: 1px solid #e4e4e4;
}

.c-popular-developer-text {
  padding: 0 20px;
  display: inline-block;
  line-height: 16px;
  vertical-align: middle;
}

.metro {
  position: relative;
  padding-left: 17px;
  min-height: 36px;
}

.metro .line-marker {
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.metro-time {
  color: #7a7a7a;
}

.address {
  margin-bottom: 9px;
  display: block;
}
