.c-new-on-cian-title {
  margin: 3px 0 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #121212;
}

.c-new-on-cian-link-item {
  margin-bottom: 10px;
  display: block;
}

.c-new-on-cian-link-item a {
  display: inline;
}
