.button {
  padding: 0;
  display: flex;
  width: var(--unit_6);
  height: var(--unit_6);
  cursor: pointer;
  background: transparent;
  border: 0;
  align-items: center;
  justify-content: center;
}
