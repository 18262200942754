.container {
  padding: 20px 16px;
  box-sizing: border-box;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 100% -4px;
  background-size: contain;
}

.description {
  margin-top: 8px;
  margin-bottom: 20px;
  width: 193px;
}
