.header {
  margin-bottom: var(--unit_0);
  padding: var(--unit_2) 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dates {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: var(--unit_2);
}

.month {
  width: 300px;
  text-align: center;
}
