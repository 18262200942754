@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  margin-top: var(--unit_3);
  padding: var(--unit_3) var(--unit_3) 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: var(--accent-main-primary);
  border-top: 1px solid var(--gray10_100);
}

.container:hover {
  color: var(--control-main-primary-hovered);
}

.container svg {
  margin-left: var(--unit_1);
}
