.container {
  width: 100%;
  min-width: 140px;
  max-width: 316px;
}

.dropdown {
  position: absolute;
  z-index: 10;
  left: 0;
  margin-top: 8px;
  padding: 16px;
  min-width: 284px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.list {
  padding: 0;
}

.list > li {
  padding: 4px 0;
  list-style: none;
}

.list > li:first-child {
  padding-top: 0;
}

.list > li:last-child {
  padding-bottom: 0;
}

.button {
  padding: 16px;
  height: 50px;
}
