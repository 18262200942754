@import '@cian/ui-kit/typography/variables.module.css';

.container {
  margin: 0 auto;
  padding: 0 20px 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.heading {
  margin-bottom: 16px;
}

.heading > h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grid {
  display: grid;
  gap: 20px 16px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.grid_fit {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.block {
  display: none;
}

.block_visible {
  display: block;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list li + li {
  margin-top: 4px;
}

.link {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  max-height: 40px;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  /* stylelint-disable-next-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
  text-overflow: ellipsis;
  color: var(--gray60_100);
}

.row:nth-child(-n + 3) .link {
  max-height: 20px;
  -webkit-line-clamp: 1;
}

.link:hover {
  color: var(--accent-main-primary);
}

.button {
  margin: 0;
  padding: 0;
  display: inline-flex;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  cursor: pointer;
  color: var(--accent-main-primary);
  background: transparent;
  border: none;
}

.actions-row {
  margin-top: 40px;
}
