.c-seo-text-title {
  margin: 0 0 15px;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: #121212;
}

.c-seo-text-col {
  padding-right: 32px;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
}

.c-seo-text-col a {
  min-height: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  border-bottom: 1px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s,
    background 0.3s;
}

.c-seo-text-col a:hover {
  color: #256ba5;
  border-bottom-color: #b6d3ed;
}

.c-seo-text-col p {
  margin: 0 0 8px;
}

.c-seo-text-col p:first-child {
  margin-top: 0;
}
