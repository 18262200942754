.c-selfads-item-add {
  background: url('./img/add.svg') 20px 20px no-repeat;
}

.c-selfads-item-map {
  background: url('./img/marker.svg') 20px 20px no-repeat;
}

.c-bottom-selfads-col {
  box-sizing: border-box;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}

.c-selfad-single {
  padding: 26px 21px 45px;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}

.c-selfad-single-title {
  margin: 0 0 5px;
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: #121212;
}

.c-selfad-single-description {
  max-width: 425px;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
}

@media screen and (max-width: 1024px) {
  .c-selfad-single,
  .c-bottom-selfads-col {
    margin-bottom: 43px;
  }
}
