.c-specialists {
  margin-top: -3px;
}

.c-specialists-title {
  margin: 0 0 16px;
  display: none;
  font-size: 28px;
  font-weight: bold;
}

.c-specialists-horizontal {
  margin-top: 43px;
}

.c-specialists-horizontal .c-specialists-title {
  display: block;
}

@media screen and (max-width: 1024px) {
  .c-specialists-title {
    display: block;
  }
  .c-specialists-horizontal {
    margin-top: 0;
  }
}
