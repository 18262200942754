.container {
  width: 100%;
  min-width: 140px;
  max-width: 316px;
}

.dropdown {
  position: absolute;
  z-index: 10;
  left: 0;
  margin-top: 8px;
  padding: 16px;
  min-width: 284px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.radio-group {
  margin-bottom: 16px;
}

.info-popup {
  top: 100%;
  left: 0;
  margin-top: 6px;
  padding: 6px 12px;
  max-width: 100%;
  font-size: 14px;
  line-height: 20px;
  white-space: pre;
  color: white;
  background: rgba(0, 0, 0, 0.8);
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.list {
  margin: 0;
  padding: 0;
}

.list > li {
  padding: 12px 0;
  list-style: none;
  border-top: 1px solid #e4e4e4;
}

.list > li:first-child {
  padding-top: 0;
  border-top: none;
}

.list > li:last-child {
  padding-bottom: 0;
}

.list-inner {
  padding: 0;
}

.list-inner > li {
  padding: 4px 0;
  list-style: none;
}

.list-inner > li:first-child {
  padding-top: 0;
}

.list-inner > li:last-child {
  padding-bottom: 0;
}

.coworking-notification {
  position: absolute;
  top: calc(100% + 13px);
}
