.c-promo-block-text-content {
  position: relative;
  padding: 17px 16px 12px 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
}

.c-promo-title {
  margin: 0 0 3px;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #121212;
}

.c-promo-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-promo-list li {
  margin-bottom: 2px;
}

.c-promo-count {
  margin-left: 7px;
  font-size: 10px;
  color: #7a7a7a;
}
