.cell {
  position: relative;
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
}

.inner {
  position: relative;
}

.day_of_week {
  color: var(--gray60_100);
}

.available {
  cursor: pointer;
  color: var(--decorative-theme-dark);
}

.disabled {
  color: var(--gray20_100);
}

.selected,
.range_start,
.range_end {
  cursor: pointer;
  color: var(--decorative-theme-white);
  background: var(--accent-main-primary);
  border-radius: var(--unit_1);
}

.cell.available:not(.selected):hover,
.cell.available:not(.range_start):hover,
.cell.available:not(.range_end):hover {
  border: 1px solid var(--gray40_100);
  border-radius: var(--unit_1);
}

.in_range {
  cursor: pointer;
  color: var(--decorative-theme-dark);
  background: var(--accent-main-secondary);
}

.in_range {
  box-shadow:
    4px 0 0 0 var(--accent-main-secondary),
    -4px 0 0 0 var(--primary_10);
}

.cell.in_range:first-of-type {
  box-shadow: 4px 0 0 0 var(--accent-main-secondary);
}

.cell.in_range:last-of-type {
  box-shadow: -4px 0 0 0 var(--accent-main-secondary);
}

.range_start:not(:nth-child(7))::before {
  position: absolute;
  top: 0;
  right: -4px;
  bottom: 0;
  width: 8px;
  content: '';
  background: var(--accent-main-secondary);
}

.range_end:not(:nth-child(1))::before {
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  width: 8px;
  content: '';
  background: var(--accent-main-secondary);
}
/* stylelint-disable selector-max-specificity */
.cell:not(.in_range):not(.range_start) + .range_end::before {
  display: none;
}
