.c-filters {
  position: relative;
  padding-top: 150px;
  height: 490px;
}

@media screen and (max-width: 1280px) {
  .c-filters-dailyrent {
    height: 460px;
  }
}

.c-filters--new {
  padding-top: 94px;
}

.c-filters-content {
  position: relative;
  z-index: 3;
  padding: 0 16px;
}

.c-filters-inner {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1120px;
}
