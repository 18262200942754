.container {
  flex: 0 0 166px;
  width: 166px;
}

.dropdown {
  position: absolute;
  z-index: 10;
  left: 0;
  margin-top: 8px;
  padding: 16px;
  min-width: 178px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
