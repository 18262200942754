.container {
  position: relative;
  height: 100%;
  border-right: 1px solid #f4f4f4;
}

.container:first-of-type .button {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.container:last-of-type .button {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.container:last-of-type .dropdown {
  right: 0;
  left: initial;
}

.container:last-of-type {
  border-right: none;
}

.button {
  padding: 20px 38px 20px 24px;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #121212;
  background: none;
  border: none;
}

.button::after {
  position: absolute;
  top: 50%;
  right: 16px;
  display: block;
  width: 6px;
  height: 6px;
  content: '';
  border-right: 2px solid #c9c9c9;
  border-bottom: 2px solid #c9c9c9;
  transition: transform 0.3s;
  transform: translateY(-50%) rotate(45deg);
}

.button:hover,
.button:focus,
.button.button--active {
  background: #e9f3fb;
  outline: none;
}

.button.button--active::after {
  transform: translateY(-10%) rotate(225deg);
}

.button.button--empty {
  color: #7a7a7a;
}

input.input,
.input {
  padding: 20px 24px;
  overflow: hidden;
  width: 100%;
  height: auto;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: text;
  color: #121212;
  background: none;
  border: none;
}

input.input::placeholder,
.input::placeholder {
  color: #7a7a7a;
}

input.input:hover,
input.input:hover,
.input:hover,
.input:focus {
  outline: none;
}
