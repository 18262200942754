.dropdown {
  position: absolute;
  z-index: 10;
  left: 0;
  margin-top: 8px;
  padding: 12px 0;
  min-width: 144px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.list {
  margin: 0;
  padding: 0;
}

.list > li {
  list-style: none;
}

.dropdown_item {
  position: relative;
  padding: 4px 16px 4px 38px;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  color: #121212;
  background: none;
  border: 0;
}

.dropdown_item:hover,
.dropdown_item:focus {
  background-color: #e9f3fb;
  outline: none;
}

.dropdown_item--active::before {
  position: absolute;
  top: 50%;
  left: 18px;
  display: block;
  width: 10px;
  height: 8px;
  content: '';
  background: url('./assets/selected.svg') center no-repeat;
  transform: translateY(-50%);
}
