.container {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  margin: 0;
  margin-bottom: 20px;
  max-width: 80%;
  text-align: center;
}
