.container {
  position: relative;
}

.dropdown-wrapper {
  position: absolute;
  z-index: var(--zIndex_popover);
  left: 0;
  margin-top: 8px;
  padding-top: 12px;
  min-width: 260px;
  background-color: var(--decorative-theme-white);
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.hidden {
  display: none;
}

.nested-checkbox-groups {
  overflow-y: auto;
  width: 260px;
  max-height: 346px;
}
