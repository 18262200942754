.c-tabs-heading {
  margin-bottom: 23px;
  display: flex;
  align-items: center;
}

.c-tabs-title {
  margin-right: 18px;
  display: block;
  height: 28px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: black;
}

.c-tabs-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-tabs-tab {
  display: inline-block;
}

.c-tabs-link {
  margin-right: 18px;
  margin-bottom: -2px;
  padding-bottom: 2px;
  display: block;
  box-sizing: border-box;
  height: 28px;
  font-weight: bold;
  line-height: 27px;
  text-transform: uppercase;
  cursor: pointer;
  color: #7a7a7a;
  border-bottom: 2px solid transparent;
}

.c-tabs-link:hover {
  color: black;
  border-color: #7a7a7a;
}

.c-tabs-link.c-tabs-link-active {
  color: black;
  border-color: #2b87db;
}
