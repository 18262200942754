.container {
  display: flex;
  align-items: center;
}

.input {
  padding-right: 16px;
}

.suffix {
  padding: 0 16px 0 0;
  font-size: 14px;
  color: #7a7a7a;
}
