.c-filters-brand {
  margin: 0 auto;
  margin-top: 26px;
  display: flex;
  width: 100%;
  max-width: 1120px;
}
.c-filters-brand--new-placement {
  margin-top: 26px;
}

.c-filters-brand-info {
  flex: 1 1 100%;
}

.c-filters-brand-info {
  position: relative;
  margin-left: 10px;
  display: block;
}

.c-filters-brand-info:hover {
  border-color: transparent;
}

.c-filters-brand-info::after {
  display: block;
  clear: both;
  content: '';
}

.c-filters-brand-flag {
  position: absolute;
  left: 0;
  bottom: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
  height: 24px;
  line-height: 23px;
  text-decoration: none;
  color: white;
  background: rgba(255, 80, 80, 0.7);
  border-radius: 2px 0 0 2px;
}

.c-filters-brand-flag::before {
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  content: '';
  border-width: 12px 10px 12px 0;
  border-style: solid;
  border-color: transparent transparent rgba(255, 80, 80, 0.7);
}

.c-filters-brand-flag::after {
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  content: '';
  border-width: 12px 10px 0 0;
  border-style: solid;
  border-color: rgba(255, 80, 80, 0.7) transparent transparent;
}

.c-filters-brand-logo {
  margin-right: 15px;
  padding: 5px;
  display: block;
  float: left;
  height: 50px;
  border-radius: 2px;
}

.c-filters-brand-logo-bg {
  margin-right: 15px;
  padding: 5px;
  display: block;
  float: left;
  height: 50px;
  background-color: white;
  border-radius: 2px;
}

.c-filters-brand-logo-bg img,
.c-filters-brand-logo img {
  height: 100%;
}

.c-filters-brand-left {
  overflow: hidden;
  min-height: 40px;
}

.c-filters-brand-link {
  text-decoration: none;
}

.c-filters-brand-title,
.c-filters-brand-text,
.c-filters-brand-tel,
.c-filters-brand-button {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  color: white;
}

.c-filters-brand-title {
  margin: 0;
  font-size: 22px;
}

.c-filters-brand-text {
  margin: 4px 0 9px;
  display: block;
}

.c-filters-brand-button {
  padding: 4px 16px 5px 15px;
  display: inline-block;
  height: 28px;
  font-size: 14px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.15);
  border: 0;
  border-radius: 14px;
  transition: background-color 0.3s;
}

.c-filters-brand-button:hover {
  color: white;
  background: rgba(255, 255, 255, 0.3);
  border-bottom-color: transparent;
}

.c-filters-brand-tel {
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}
