.c-specialists-item {
  display: block;
  height: auto;
  color: black;
}

.c-specialists-item::after {
  display: block;
  clear: both;
  content: '';
}

.c-specialists-item:hover {
  color: black;
  border-color: transparent;
}

.c-specialists-photo {
  display: block;
  float: left;
  box-sizing: border-box;
  width: 46px;
  height: 46px;
  border: 1px solid #e8e8e8;
}

.c-specialists-photo-round {
  border-radius: 50%;
}

.c-specialists-info {
  margin-left: 60px;
  padding: 3px 0 0;
  overflow: hidden;
  min-height: 46px;
  white-space: normal;
  text-overflow: ellipsis;
}

.c-specialists-name {
  display: inline;
  font-weight: bold;
  line-height: 16px;
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s;
}

.c-specialists-summary {
  margin-top: 3px;
  display: block;
  color: #7a7a7a;
}

.c-specialists-item:hover .c-specialists-name {
  border-bottom-color: hsla(0, 0%, 7%, 0.3);
}
