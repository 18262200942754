.container {
  margin: 0 -40px 16px;
  padding: 0 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--gray10_100);
}

.header {
  margin-bottom: 16px;
}

.search {
  display: flex;
}

.input {
  position: relative;
  margin-right: 20px;
  width: 320px;
}
