.container {
  position: relative;
}

.container a:not(.link) {
  position: relative;
  z-index: 1;
}

.link {
  position: static;
}

.link-area {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}
