.container {
  flex: 0 0 156px;
  max-width: 156px;
}

.dropdown {
  position: absolute;
  z-index: 10;
  left: 0;
  margin-top: 8px;
  padding: 16px;
  min-width: 300px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.list {
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.list > li {
  list-style: none;
}

.button {
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  color: #121212;
  background: none;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
}

.button:hover,
.button:focus {
  border: 1px solid #7a7a7a;
  outline: none;
}

.button--active {
  color: #2b87db;
  background-color: #e9f3fb;
  border-color: #e9f3fb;
}

.button--active:hover,
.button--active:focus {
  border-color: rgba(43, 135, 219, 0.54);
}

.list-other {
  margin: 0;
  padding: 0;
}

.list-other > li {
  padding: 4px 0;
  list-style: none;
}

.list-other > li:first-child {
  padding-top: 0;
}

.list-other > li:last-child {
  padding-bottom: 0;
}
