.container {
  position: relative;
  flex: 1 0;
  min-width: 280px;
}

.heading {
  margin-bottom: 16px;
  font-weight: bold;
}

.dropdown {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-top: 8px;
  padding: 16px 20px 20px;
  background: var(--decorative-theme-white);
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}
