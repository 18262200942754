.c-popular-title {
  position: relative;
  margin: 0 0 15px;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  color: black;
}

.c-popular-title-additional-link {
  position: absolute;
  right: 0;
  bottom: 2px;
  font-size: 12px;
  font-weight: normal;
}

.c-all-propositions {
  margin-bottom: 36px;
  display: inline-block;
  min-height: 19px;
}

.c-all-propositions:last-of-type {
  margin-bottom: 38px;
}
