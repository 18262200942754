.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  color: white;
  background-image: linear-gradient(110deg, rgba(43, 71, 86, 0.7), rgba(43, 71, 86, 0.1));
  border-radius: 2px;

  &:hover .title {
    border-bottom-color: hsla(0, 0%, 100%, 0.3);
  }

  &.with-icon {
    justify-content: flex-start;
    background-image: none;

    & :first-child {
      margin-top: 120px;
    }
  }
}

.title {
  margin: 0 0 2px;
  display: inline;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  border-bottom: 2px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s;
}

.text {
  margin-top: 10px;
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  opacity: 0.8;
}
