.wrapper {
  position: relative;
  z-index: 5;
  /* padding: 0 16px; */
}

.container {
  margin: 0 auto;
  max-width: 1109px;
}

.title {
  margin-bottom: 46px;
  font-size: 38px;
  font-weight: bold;
  line-height: 1.21;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

.title-transparent {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.children-day {
  font-size: 26px;
}

.children-day-link {
  display: inline-block;
  width: 700px;
  height: 45px;
  font-size: 0;
  text-decoration: none;
  color: inherit;
  background-image: url('./assets/children_day.png');
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
}

.dobroshrift {
  position: relative;
  margin-bottom: 46px;
  display: inline-flex;
  height: 45px;
}

.dobroshrift-link {
  position: absolute;
  top: -25px;
  right: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  text-decoration: none;
  color: white;
  border-bottom: 1px solid transparent;
  transition: border 0.3s;
}

.dobroshrift-link:hover {
  border-bottom-color: white;
}
