.container {
  padding-bottom: var(--unit_10);

  & > :nth-child(1) {
    margin-bottom: var(--unit_6);
  }
}

.carousel {
  & > * {
    /* stylelint-disable-next-line declaration-no-important */
    flex: 0 0 auto !important;
  }
}
