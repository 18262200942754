/* promo */
.c-promo-container {
  padding: 16px 0 56px;
}

/* new styles */
.c-popular-links-group {
  margin-bottom: 20px;
}

.c-popular-links-group:last-child {
  margin: 0;
}

.c-popular-links-group-title {
  margin: 0 0 2px;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #121212;
}

.c-popular-links-group_collapsed .c-popular-links-group-list {
  overflow: hidden;
  max-height: 122px;
}

.c-popular-links-item {
  display: block;
  text-decoration: none;
}

.c-popular-links-item-text {
  padding: 0 4px 1px;
  display: inline;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
  color: #2b87db;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
}

.c-popular-links-item:hover .c-popular-links-item-text {
  color: #256ba5;
  border-color: #007aeb;
  border-color: rgba(0, 122, 235, 0.3);
}

.c-popular-links-item-count {
  font-size: 10px;
  line-height: 12px;
  vertical-align: middle;
  color: #7a7a7a;
}

.c-popular-links-group-toggle {
  position: relative;
  padding-bottom: 1px;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #2b87db;
  border-bottom: 1px solid rgba(0, 122, 235, 0.3);
  transition: all 0.3s;
}

.c-popular-links-group-toggle:hover {
  color: #256ba5;
}

.c-popular-links-group-toggle-icon {
  position: absolute;
  top: 6px;
  right: -13px;
  width: 8px;
  height: 5px;
  background: url('./img/blue-arrow.svg') no-repeat;
  background-size: 100%;
  transition: all 0.3s;
  transform: rotate(180deg);
}

.c-popular-links-group_collapsed .c-popular-links-group-toggle-icon {
  top: 7px;
  transform: rotate(0deg);
}

/* commerce promo blocks */
