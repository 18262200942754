.c-promo-block {
  margin-bottom: 8px;
  overflow: hidden;
  background: white;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
}

.c-promo-block-image {
  position: relative;
  width: 49.35%;
  height: 200px;
  background: white;
}

.c-promo-block-image-replace-adfox {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-promo-block-image-left .c-promo-block-image {
  margin-right: 1%;
  float: left;
}

.c-promo-block-image-right .c-promo-block-image {
  margin-left: 1%;
  float: right;
}

.c-promo-block-image-hide .c-promo-block-image {
  display: block;
}

.c-promo-block-image-show .c-promo-block-image {
  display: none;
}

@media screen and (max-width: 1199px) {
  .c-promo-block-image-hide .c-promo-block-image {
    display: none;
  }
  .c-promo-block-image-show .c-promo-block-image {
    display: block;
  }
}

.c-promo-block-text {
  position: relative;
  width: 49.35%;
  height: 200px;
  background: white;
}

.c-promo-block-image-left .c-promo-block-text {
  float: right;
}

.c-promo-block-image-right .c-promo-block-text {
  float: left;
}

.c-promo-block-image-none .c-promo-block-text {
  width: 100%;
}

.c-promo-block-image-hide .c-promo-block-text {
  width: 49.35%;
}

.c-promo-block-image-show .c-promo-block-text {
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .c-promo-block-image-hide .c-promo-block-text {
    width: 100%;
  }
  .c-promo-block-image-show .c-promo-block-text {
    width: 49.35%;
  }
}
