.container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  background-image: url('./assets/default.jpg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  animation: 3s linear 0s 1 parallax;
  animation-fill-mode: forwards;
}

.old-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
}

.old-image::after {
  display: inline-block;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(24, 41, 48, 0.4);
}

.index {
  background-image: url('./assets/banner-bg.jpg');
}

.daily-rent {
  background-image: url('./assets/daily-banner-bg.jpg');
  background-position: left bottom;
  animation: none;
}

.commercial {
  background-image: url('./assets/commerce-banner-bg.jpg');
}

.sale {
  background-image: url('./assets/sale-banner-bg.jpg');
}

.rent {
  background-image: url('./assets/rent-banner-bg.jpg');
}

.new-buildings {
  background-image: url('./assets/new-banner-bg.png');
}

/* stylelint-disable */
@media only screen and (-webkit-min-device-pixel-ratio: 2), not all, not all, only screen and (min-resolution: 192dpi) {
  /* stylelint-enable */
  .index {
    background-image: url('./assets/banner-bg@2x.jpg');
  }

  .daily-rent {
    background-image: url('./assets/daily-banner-bg@2x.jpg');
  }

  .commercial {
    background-image: url('./assets/commerce-banner-bg@2x.jpg');
  }

  .sale {
    background-image: url('./assets/sale-banner-bg@2x.jpg');
  }

  .rent {
    background-image: url('./assets/rent-banner-bg@2x.jpg');
  }

  .new-buildings {
    background-image: url('./assets/new-banner-bg@2x.png');
  }
}

/**
 * Обновляется базовое положение фона для всех вертикалей кроме новостроек и коммерческой
 * при подгонке новых изображений используется шаблон в фигме
 * https://www.figma.com/file/EI5BbDOoYxuoILzlgF6yas/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F.-%D0%9F%D1%80%D0%B8%D0%BC%D0%B5%D1%80-%D0%B8-%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD?type=design&node-id=104-2098&mode=design&t=TTuSSJZtLWGXbn9V-0
 * для обновления используется процесс описанный в confluence
 * https://conf.cian.tech/pages/viewpage.action?pageId=1349978694
 */

.commercial :not(.old-image) {
  background-image: url('./assets/commercial.jpg');
  background-position-y: top;
}

@keyframes parallax {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.07, 1.07);
  }
}
