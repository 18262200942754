.c-non-building-welcome {
  position: relative;
  z-index: 4;
  margin-bottom: -112px;
  padding-top: 110px;
  max-height: 388px;
  background-color: white;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition:
    max-height 0.75s,
    visibility 0s linear 0.75s;
}

.c-non-building-welcome_hidden {
  margin-top: 0;
  visibility: hidden;
  max-height: 0;
}

.c-non-building-welcome__close {
  position: absolute;
  top: 15px;
  right: 20px;
  margin-top: 110px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url('./img/close.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.7;
  transition: opacity 0.15s;
}

.c-non-building-welcome__close:hover {
  opacity: 1;
}

.c-non-building-welcome__content {
  padding: 32px 0 41px;
  text-align: center;
}

.c-non-building-welcome__image {
  margin-bottom: 12px;
  display: inline-block;
  width: 71px;
  height: 88px;
  background-image: url('./img/builder-region.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.c-non-building-welcome__title {
  margin: 0 0 9px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
}

.c-non-building-welcome__description {
  margin: 0 0 16px;
  font-size: 16px;
  line-height: 1.5;
}
