.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.label {
  white-space: nowrap;
}

.postfix {
  margin-left: 8px;
  padding: 1px 8px;
  height: fit-content;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px;
}

.postfix-new {
  color: white;
  background: var(--accent-negative-primary);
}

.postfix-soon {
  color: var(--decorative-theme-dark);
  background: #e8e9ec;
}

.postfix-info {
  margin-left: 4px;
  display: flex;

  & svg path {
    color: var(--accent-main-primary);
  }
}
