.container {
  position: absolute;
  z-index: 10;
  left: 0;
  margin-top: 8px;
  padding: 16px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.list {
  margin: 0;
  padding: 0;

  & > li {
    padding: 12px 0;
    list-style: none;
    border-top: 1px solid #e4e4e4;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.list-inner {
  padding: 0;

  & > li {
    padding: 4px 0;
    list-style: none;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
