.c-com-promo-block-links-col {
  margin-right: 70px;
  float: left;
}

.c-com-promo-block-links-col:last-child {
  margin-right: 0;
}

.c-com-promo-block-links-item {
  text-decoration: none;
}

.c-com-promo-link-text {
  margin-bottom: 3px;
  display: block;
  font-weight: bold;
  line-height: 1.43;
  color: white;
  border-bottom: 1px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s,
    background 0.3s;
}

.c-com-promo-block-links-item:hover .c-com-promo-link-text {
  border-bottom-color: hsla(0, 0%, 100%, 0.5);
}

.c-com-promo-link-count {
  display: block;
  font-size: 10px;
  line-height: 1.4;
  color: hsla(0, 0%, 100%, 0.8);
}
