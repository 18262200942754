.c-new-promo-block {
  margin-bottom: 8px;
  height: 200px;
  color: white;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border-radius: 2px;
}

.c-promo-title {
  margin: 0 0 16px;
  font-size: 28px;
  font-weight: bold;
}

.c-new-promo-block_office {
  background-image: url('./img/office.jpg');
}

.c-new-promo-block_buy-flat {
  background-image: url('./img/buy-flat.jpg');
}

.c-new-promo-block_buy-room {
  background-image: url('./img/buy-room.jpg');
}

.c-new-promo-block_buy-site {
  background-image: url('./img/buy-site.jpg');
}

.c-new-promo-block_buy-new-flat {
  background-image: url('./img/buy-new-flat.jpg');
}

.c-new-promo-block_buy-new-flat2 {
  background-image: url('./img/buy-new-flat2.jpg');
}

.c-new-promo-block_buy-townhouse {
  background-image: url('./img/buy-townhouse.jpg');
}

.c-new-promo-block_rent-flat {
  background-image: url('./img/rent-flat.jpg');
}

.c-new-promo-block_daily_rent {
  background-image: url('./img/img_daily_rent.jpg');
}

.c-new-promo-block_hostels {
  background-image: url('./img/img_hostels.jpg');
}

.c-new-promo-block_house {
  background-image: url('./img/img_rent_house.jpg');
}

.c-new-promo-block_garage {
  background-image: url('./img/img_rent_garage.jpg');
}

.c-new-promo-block_bs-center {
  background-image: url('./img/img_business_centre.jpg');
}

.c-new-promo-block_rent-room {
  background-image: url('./img/buy-room.jpg');
}

.c-new-promo-block_rent-studio {
  background-image: url('./img/rent-studio.jpg');
}

.c-new-promo-block_rent-flat-daily {
  background-image: url('./img/rent-flat-daily.jpg');
}

.c-new-promo-block_rent-room-daily {
  background-image: url('./img/rent-room-daily.jpg');
}

.c-new-promo-block_rent-house-daily {
  background-image: url('./img/rent-house-daily.jpg');
}

.c-new-promo-block_rent-1room-flat-daily {
  background-image: url('./img/rent-1room-flat-daily.jpg');
}

.c-new-promo-block_under-construction {
  background-image: url('./img/under-construction.jpg');
}

.c-new-promo-block_developers {
  background-image: url('./img/developers.jpg');
}

.c-new-promo-block_builders {
  background-image: url('./img/builders.jpg');
}

.c-new-promo-block_blue {
  background-color: #5894c9;
}

.c-new-promo-block-content {
  padding: 27px 15px 25px 25px;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(110deg, rgba(43, 71, 86, 0.7), rgba(43, 71, 86, 0.1));
  border-radius: 2px;

  &.no-shadow {
    background-image: none;
  }
}

.c-new-promo-block-content-link {
  padding: 0;
}

.c-new-promo-block-content-link-icon {
  padding: 0;
  background-image: none;
}

.c-new-promo-block-title {
  margin: 0 0 10px;
  overflow: hidden;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.18;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-new-promo-block-links-item {
  display: block;
  text-decoration: none;
}

.c-new-promo-link-text {
  margin: 0 8px 3px 0;
  display: inline;
  line-height: 1.71;
  color: white;
  border-bottom: 1px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s,
    background 0.3s;
}

.c-new-promo-block-links-item:hover .c-new-promo-link-text {
  border-bottom-color: hsla(0, 0%, 100%, 0.5);
}

.c-new-promo-link-count {
  display: inline;
  font-size: 10px;
  font-weight: bold;
  line-height: 2.3;
  color: hsla(0, 0%, 100%, 0.8);
}

.c-new-promo-block-content-link-item {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: white;
}

.c-new-promo-block_white .c-new-promo-block-content-link-item {
  color: #121212;
}

.c-new-promo-block-link-icon-content {
  position: relative;
  padding: 123px 25px 0;
  background-repeat: no-repeat;
  background-position: center 25px;
  background-size: 90px 90px;
  transform: translate(0);
}

.c-new-promo-block-link-icon_house {
  background-image: url('./img/house-icon.svg');
  background-position: center 35px;
  background-size: 90px 71px;
}

.c-new-promo-block-link-icon_garage {
  background-image: url('./img/garage-icon.svg');
}

.c-new-promo-block-link-icon_commerce {
  background-image: url('./img/commerce-icon.svg');
  background-position: center 35px;
  background-size: 90px 78px;
}

.c-new-promo-block-link-icon_flat {
  background-image: url('./img/flat-icon.svg');
}

.c-new-promo-block-link-icon_hostel {
  background-image: url('./img/hostel-icon.svg');
}

.c-new-promo-block-link-icon_catalog {
  padding: 93px 65px 0;
}

.c_new_promo_block-newbuildings_booking {
  background-image: url('./img/booking.svg');
  background-position: center 12px;
  background-size: 104px 104px;
}

.c-new-promo-block-link-icon_sale {
  background-image: url('./img/sale-icon.svg');
}

.c-new-promo-block-link-icon_sales-leaders {
  color: black;
  background-image: url('./img/sales-leaders.svg');
}

.c-new-promo-block-content-link-title {
  margin: 0 0 2px;
  display: inline;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  border-bottom: 2px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s,
    background 0.3s;
}

.c-new-promo-block-content-link-item:hover .c-new-promo-block-content-link-title {
  border-bottom-color: hsla(0, 0%, 100%, 0.3);
}

.c-new-promo-block_white .c-new-promo-block-content-link-item:hover .c-new-promo-block-content-link-title {
  border-bottom-color: rgba(0, 0, 0, 0.3);
}

.c-new-promo-block-content-link-text {
  margin-top: 10px;
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  opacity: 0.8;
}

.c-new-promo-block-content-link-text--gray {
  color: #121212;
}

.c-new-promo-block_map {
  background: url('./img/bg_map.png') no-repeat 50% 50%;
  background-size: cover;
  border: solid 1px #e4e4e4;
}

.c-promo-info-block-map-content {
  padding: 37px 0 0;
  height: 100%;
  /* stylelint-disable */
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.9) 35%,
    rgba(255, 255, 255, 0.9) 65%,
    rgba(255, 255, 255, 0.5)
  );
  /* stylelint-enable */
}

.c-promo-info-map-title,
.c-promo-info-map-text {
  display: block;
  text-align: center;
  color: #121212;
}

.c-promo-info-map-title {
  margin-bottom: 7px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
}

.c-promo-info-map-text {
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 1.38;
}

.c-promo-info-map-button {
  margin: 0 auto;
  padding: 0 16px;
  display: block;
  width: 135px;
  height: 28px;
  font-weight: bold;
  line-height: 1.8;
  border-radius: 14px;
}

.c-promo-info-map-button {
  color: #2b87db;
  border: 1px solid rgba(43, 135, 219, 0.5);
}

.c-promo-info-map-button:hover {
  color: #256ba5;
  border-color: rgba(37, 107, 165, 0.5);
}

.c-promo-container {
  padding: 16px 0 56px;
}

@media screen and (max-width: 1199px) {
  .cg-col-promo-s-6 {
    width: calc(25% - 0.5rem);
  }
  .cg-col-promo-s-8 {
    width: calc(33.33333333333333% - 0.5rem); /* stylelint-disable-line */
  }
  .cg-col-promo-s-12 {
    width: calc(50% - 0.5rem);
  }
  .cg-col-promo-s-16 {
    width: calc(66.66666666666666% - 0.5rem); /* stylelint-disable-line */
  }
  .cg-col-promo-s-18 {
    width: calc(75% - 0.5rem);
  }
  .cg-col-promo-s-24 {
    width: calc(100% - 0.5rem);
  }
}

/* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
@media only screen and (-webkit-min-device-pixel-ratio: 2), not all, not all, only screen and (min-resolution: 192dpi) {
  /* stylelint-disable-line */
  .c-new-promo-block_office {
    background-image: url('./img/office@2x.jpg');
  }

  .c-new-promo-block_buy-flat {
    background-image: url('./img/buy-flat@2x.jpg');
  }

  .c-new-promo-block_buy-room {
    background-image: url('./img/buy-room@2x.jpg');
  }

  .c-new-promo-block_buy-site {
    background-image: url('./img/buy-site@2x.jpg');
  }

  .c-new-promo-block_buy-new-flat {
    background-image: url('./img/buy-new-flat@2x.jpg');
  }

  .c-new-promo-block_buy-new-flat2 {
    background-image: url('./img/buy-new-flat2@2x.jpg');
  }

  .c-new-promo-block_buy-townhouse {
    background-image: url('./img/buy-townhouse@2x.jpg');
  }

  .c-new-promo-block_rent-flat {
    background-image: url('./img/rent-flat@2x.jpg');
  }

  .c-new-promo-block_house {
    background-image: url('./img/img_rent_house@2x.jpg');
  }

  .c-new-promo-block_garage {
    background-image: url('./img/img_rent_garage@2x.jpg');
  }

  .c-new-promo-block_bs-center {
    background-image: url('./img/img_business_centre@2x.jpg');
  }

  .c-new-promo-block_rent-room {
    background-image: url('./img/buy-room@2x.jpg');
  }

  .c-new-promo-block_rent-studio {
    background-image: url('./img/rent-studio@2x.jpg');
  }

  .c-new-promo-block_rent-flat-daily {
    background-image: url('./img/rent-flat-daily@2x.jpg');
  }

  .c-new-promo-block_rent-room-daily {
    background-image: url('./img/rent-room-daily@2x.jpg');
  }

  .c-new-promo-block_rent-house-daily {
    background-image: url('./img/rent-house-daily@2x.jpg');
  }

  .c-new-promo-block_rent-1room-flat-daily {
    background-image: url('./img/rent-1room-flat-daily@2x.jpg');
  }

  .c-new-promo-block_under-construction {
    background-image: url('./img/under-construction@2x.jpg');
  }

  .c-new-promo-block_developers {
    background-image: url('./img/developers@2x.jpg');
  }

  .c-new-promo-block_daily_rent {
    background-image: url('./img/img_daily_rent@2x.jpg');
  }

  .c-new-promo-block_hostels {
    background-image: url('./img/img_hostels@2x.jpg');
  }
}

.c-domrf_promo_container {
  margin-right: 0.5rem;
  margin-bottom: 8px;
  display: block;
  height: 139px;
  text-decoration: none;
  background-color: white;
  background-image: url('./img/domrf_bg.jpg');
  background-repeat: no-repeat;
  background-position: right top;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
}

.c-domrf_promo_content {
  width: 66%;
  height: 100%;
  color: black;
  background: linear-gradient(90.67deg, white 21.65%, #fafbfc 43.04%, rgba(254, 254, 254, 0) 99.2%);
}

.c-domrf_promo_description {
  padding-left: 130px;
  display: flex;
  height: 100%;
  background-image: url('./img/domrf_logo.svg');
  background-repeat: no-repeat;
  background-position: left 35px top 50%;
  background-size: 56px 56px;
  justify-content: center;
  align-items: flex-start;
  flex-flow: nowrap column;
}

.c-domrf_promo_title {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
}

.c-domrf_promo_text {
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 1110px) {
  .c-domrf_promo_container {
    background-position: right -30px top;
  }
}

/* замена calc(20px - 0.5rem) для фикса верстки в ms edge */
/* при максимальной ширине колонки */
/* stylelint-disable-next-line */
:global(.cg-container-fluid-xs) {
  padding-right: 14px;
}
