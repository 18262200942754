/* stylelint-disable selector-class-pattern */
@media screen and (max-width: 1199px) {
  .cg-col-promo-s-6 {
    width: calc(25% - 0.5rem);
  }
  .cg-col-promo-s-8 {
    width: calc(33.33333333333333% - 0.5rem); /* stylelint-disable-line */
  }
  .cg-col-promo-s-12 {
    width: calc(50% - 0.5rem);
  }
  .cg-col-promo-s-16 {
    width: calc(66.66666666666666% - 0.5rem); /* stylelint-disable-line */
  }
  .cg-col-promo-s-18 {
    width: calc(75% - 0.5rem);
  }
  .cg-col-promo-s-24 {
    width: calc(100% - 0.5rem);
  }
}

.c-com-promo-block {
  margin-bottom: 8px;
  height: 140px;
  color: white;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border: 0;
  border-radius: 2px;
}

.c-com-promo-block_office {
  background-image: url('./img/office.jpg');
}

.c-com-promo-block_storage {
  background-image: url('./img/storage.jpg');
}

.c-com-promo-block_shop {
  background-image: url('./img/shop.jpg');
}

.c-com-promo-block_industry {
  background-image: url('./img/industry.jpg');
}

.c-com-promo-block_building {
  background-image: url('./img/building.jpg');
}

.c-com-promo-block_free {
  background-image: url('./img/free.jpg');
}

.c-com-promo-block_business {
  background-image: url('./img/business.jpg');
}

.c-com-promo-block_commerce {
  background-image: url('./img/commerce.jpg');
}

.c-com-promo-block_garage {
  background-image: url('./img/garage.jpg');
}

.c-com-promo-commercialBsCenter {
  /* stylelint-disable-line */
  background-image: url('./img/catalog.jpg');
}

.c-com-promo-commercialShoppingCenter {
  /* stylelint-disable-line */
  background-image: url('./img/shoping_center.jpg');
}

.c-com-promo-block-content {
  padding: 20px 15px 25px 25px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #2b4756;
  background-color: rgba(43, 71, 86, 0.7);
  border-radius: 2px;
}

.c-com-promo-block-title {
  margin: 0 0 14px;
  overflow: hidden;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.18;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-com-promo-block-catalog-wrap {
  position: relative;
  top: 50%;
  margin-top: -15px;
  display: block;
  text-align: center;
}

.c-com-promo-block-catalog-text {
  padding-bottom: 1px;
  display: inline;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.18;
  color: white;
  border-bottom: 2px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s,
    background 0.3s;
}

.c-com-promo-block_catalog:hover .c-com-promo-block-catalog-text {
  border-bottom-color: hsla(0, 0%, 100%, 0.3);
}

/* замена calc(20px - 0.5rem) для фикса верстки в ms edge */
/* при максимальной ширине колонки */
/* stylelint-disable-next-line */
:global(.cg-container-fluid-xs) {
  padding-right: 14px;
}
