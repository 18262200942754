.list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.list-element > a {
  margin-bottom: -8px;
  padding: 9px 16px 16px;
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.38;
  text-decoration: none;
  color: white;
  background-color: rgba(37, 37, 37, 0.6);
  border-radius: 4px 4px 0 0;
}

.list-element--active > a,
.list-element > a:hover {
  color: var(--decorative-theme-dark);
  background-color: #f1f1f1;
}

.list-element--active > a {
  font-weight: bold;
}
