.c-filters-text {
  position: absolute;
  z-index: 2;
  left: 5px;
  /* 490 - 94 - 252 - 20 - 18 = 106 */
  bottom: -106px;
  overflow: hidden;
  max-width: 100%;
  font-size: 12px;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.7);
  transform: translateY(100%);
}

.c-filters-text--new-placement {
  /* 490 - 94 - 252 - 20 - 18 = 106 */
  bottom: -106px;
}

.c-filters-text span {
  display: inline-block;
}
