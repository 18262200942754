.container {
  display: flex;
  flex-direction: column;
  gap: var(--unit_3);
  text-decoration: none;

  & > img {
    content-visibility: auto;
  }
}

.map-container {
  position: relative;
  display: inline-flex;
}

.map-button {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: var(--unit_2);
  width: 100%;
}
