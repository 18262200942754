.container {
  margin-bottom: 92px;
  padding-right: 20px;
}

.center {
  text-align: center;
}

.link {
  margin-bottom: 18px;
  display: inline-block;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.21;
  text-decoration: none;
  color: #121212;
}

.link:hover {
  text-decoration: none;
  color: #e84260;
}

.link::after {
  position: relative;
  top: -1px;
  margin-left: 10px;
  display: inline-block;
  width: 9px;
  height: 12px;
  content: '';
  background: url('./img/arrow.svg') no-repeat;
  background-size: 9px 12px;
}

.link:hover {
  color: #e84260;
}

.link:hover::after {
  background-image: url('./img/arrow-hover.svg');
}

.vacancies {
  display: flex;
}

.vacancy {
  position: relative;
  min-width: 292px;
  height: 174px;
  background: white;
  border: solid 1px #e4e4e4;
  flex: 1;
}

@media screen and (max-width: 1231px) {
  .vacancy:nth-of-type(3) {
    display: none;
  }
}

.vacancy_link {
  padding: 15px 23px 23px;
  display: block;
  height: 100%;
  text-decoration: none;
  color: #121212;
}

.vacancy_title {
  overflow: hidden;
  height: 2.66em; /* = line-height (1.33) * lines number (2) */
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33em;
}

.vacancy_salary {
  margin: 7px 0 18px;
  width: 241px;
  height: 18px;
  font-size: 14px;
}

.company {
  position: absolute;
  bottom: 24px;
  padding: 0 23px;
  width: 100%;
}

.company_link {
  display: flex;
  height: 44px;
  font-size: 14px;
  text-decoration: none;
  color: #121212;
}

.company ul {
  margin: 0;
  padding: 0;
  width: calc(100% - 56px); /* 56px — ширина логотипа компании + отступ справа */
  list-style: none;
}

.company li {
  padding: 0;
}

.company_logo {
  margin-right: 12px;
  display: block;
  width: 44px;
  height: 44px;
}

.company_name {
  margin-bottom: 1px;
  overflow: hidden;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.company_age {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #a0a0a0;
}

.vacancy + .vacancy {
  margin-left: 8px;
}

.new_vacancy {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  min-width: 292px;
  text-align: center;
  justify-content: center;
  flex: 1;
}

.text {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
}

.button {
  margin-left: 8px;
  padding: 3px 16px 5px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-decoration: none;
  cursor: pointer;
  color: white;
  background-color: #2b87db;
  border: 0;
  border-radius: 42px;
  outline: none;
}

.button:hover,
.button:focus {
  background-color: #256ba5;
}
