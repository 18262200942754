.link {
  padding: 8px 0;
  display: block;
  flex: 1 1 0;
  text-decoration: none;
}

.container {
  padding: 12px 20px;
  display: flex;
  align-items: center;
}

.link:first-child {
  border-left: 1px solid var(--light-stroke-primary, #c9d1e5);
  border-radius: 12px 0 0 12px;
}

.link {
  border-top: 1px solid var(--light-stroke-primary, #c9d1e5);
  border-right: 1px solid var(--light-stroke-primary, #c9d1e5);
  border-bottom: 1px solid var(--light-stroke-primary, #c9d1e5);
}

.link:last-child {
  border-radius: 0 12px 12px 0;
}

.link:hover {
  box-shadow: 0 4px 16px var(--overlay-control-dark);
}

.icon {
  margin-right: 20px;
  flex: none;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.content {
  flex: auto;
}

.title {
  margin-bottom: 4px;
}

.arrow {
  margin-left: 20px;
  flex: none;
}

.link.black-friday {
  background: var(--decorative-theme-blue);
  border: 0;
}

.link.black-friday .icon {
  width: 70px;
  height: 48px;
}
