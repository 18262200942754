.clearfix::after {
  display: table;
  clear: both;
  content: '';
}

.c-magazine-container {
  margin-bottom: 92px;
}

.c-magazine-container-fluid {
  padding-right: 20px;
}

.c-magazine-box {
  border: solid 1px #e4e4e4;
}

.c-licence {
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #7a7a7a;
}

.c-licence-link {
  text-decoration: none;
  color: #256ba5;
}

.c-magazine-link {
  margin-bottom: 18px;
  display: inline-block;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.21;
  text-decoration: none;
  color: #121212;
}

.c-magazine-link:hover {
  text-decoration: none;
  color: #e84260;
}

.c-magazine-link::after {
  position: relative;
  top: -1px;
  margin-left: 10px;
  display: inline-block;
  width: 9px;
  height: 12px;
  content: '';
  background: url('./img/icn-read-more-arrow.svg') no-repeat;
  background-size: 9px 12px;
}

.c-magazine-link:hover {
  color: #e84260;
}

.c-magazine-link:hover::after {
  background: url('./img/breadcrumb-arrow-hover.svg') no-repeat;
  background-size: 9px 12px;
}

.c-white-pad {
  margin: 0;
  padding: 21px 24px 24px;
  width: 66%;
  background-color: white;
  border-radius: 2px;
}

.c-white-news {
  width: 34%;
}

.c-magazine-subtitle {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  text-decoration: none;
  letter-spacing: 1px;
  color: #121212;
  opacity: 0.92;
}

.c-magazine-delimiter {
  margin-top: 18px;
  height: 2px;
  background-color: #182930;
  border-radius: 0.5px;
}

.c-articles-widget {
  margin-right: 0 !important; /* stylelint-disable-line */
}

.c-articles-first {
  position: relative;
  margin-right: 0;
  height: 400px;
  text-decoration: none;
  cursor: pointer;
  background-color: #182930;
}

.c-articles-first-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  opacity: 0.6;
}

.c-articles-first-content {
  position: absolute;
  padding: 16px 29px 21px 20px;
  width: 100%;
  height: 100%;
}

.c-articles-first .c-articles-type {
  position: relative;
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 1px;
  color: #e84260;
}

.c-articles-first .c-articles-title {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  color: white;
}

.c-articles-first .c-articles-bottom {
  position: absolute;
  bottom: 19px;
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  text-align: right;
  color: white;
}

.c-articles-first .c-articles-bottom .c-articles-views {
  position: relative;
  margin-left: 20px;
}

.c-articles-first .c-articles-bottom .c-articles-views::before {
  position: absolute;
  top: 0;
  left: -20px;
  width: 16px;
  height: 16px;
  content: url('./img/views.svg');
}

.c-articles-first .c-articles-bottom .c-articles-comments {
  position: relative;
  margin-left: 34px;
}

.c-articles-first .c-articles-bottom .c-articles-comments::before {
  position: absolute;
  top: 0;
  left: -20px;
  width: 16px;
  height: 16px;
  content: url('./img/comments.svg');
}

.c-articles-first:hover .c-articles-first-image {
  opacity: 0.7;
}

.c-articles-container {
  margin-right: 0;
  margin-left: 24px;
  width: calc(50% + 0.5rem - 24px);
}

.c-articles-container .c-articles-item,
.c-blogs-container .c-articles-item,
.c-news-container .c-news-item {
  padding-top: 14px;
  padding-bottom: 15px;
  font-size: 14px;
  line-height: 1.29;
  border-width: 0;
  border-style: solid;
  border-color: #e4e4e4;
  border-bottom-width: 1px;
}

.c-articles-container .c-articles-item .c-articles-title,
.c-blogs-container .c-articles-item .c-articles-title,
.c-news-container .c-news-item .c-news-title {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: #121212;
  opacity: 0.92;
}

.c-articles-container .c-articles-item .c-articles-title,
.c-blogs-container .c-articles-item .c-articles-title {
  margin-bottom: 6px;
}

/* stylelint-disable */
.c-articles-container .c-articles-item .c-articles-title:hover,
.c-blogs-container .c-articles-item .c-articles-title:hover,
.c-news-container .c-news-item .c-news-title:hover,
.c-articles-container .c-articles-item .c-articles-image-link:hover + .c-articles-title,
.c-blogs-container .c-articles-item .c-articles-image-link:hover + .c-articles-title {
  /* stylelint-enable */
  color: #e84260;
}

.c-articles-container .c-articles-item .c-articles-caption,
.c-blogs-container .c-articles-item .c-articles-caption,
.c-news-container .c-news-item .c-news-caption {
  text-decoration: none;
  color: #121212;
}

.c-articles-container .c-articles-item .c-articles-image,
.c-blogs-container .c-articles-item .c-articles-image {
  margin-bottom: 14px;
  margin-left: 19px;
  float: right;
  width: 100px;
  height: 66px;
  background-color: #e4e4e4;
  background-position: 50% 50%;
  background-size: cover;
}

.c-articles-author {
  margin-top: 6px;
  margin-right: 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  text-decoration: none;
  color: #7a7a7a;
}

.c-comments-icon {
  top: -2px;
  margin-right: 4px;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url('./img/icn-comments.svg') no-repeat 50%;
}

.c-comments-count {
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: #7a7a7a;
}

.c-articles-view-all,
.c-news-view-all {
  margin-top: 14px;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  text-align: left;
  text-decoration: none;
  color: #121212;
  opacity: 0.92;
}

.c-articles-view-all:hover,
.c-news-view-all:hover {
  color: #e84260;
}

.c-news-container {
  margin-right: 20px;
  margin-left: 0;
  display: table;
  width: 100%;
}

.c-news-container .c-news-time {
  position: relative;
  bottom: -1.5px;
  margin-right: 8px;
  float: left;
  width: 45px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #e84260;
}

.c-news-container .c-news-title {
  display: inline-block !important; /* stylelint-disable-line */
  width: calc(100% - 56px);
}

.c-magazine-subtitle::after {
  position: absolute;
  top: 4px;
  right: 0;
  display: block;
  width: 6px;
  height: 8px;
  content: '';
  background: url('./img/read_more_arrow.svg') no-repeat;
}

.c-magazine-subtitle:hover {
  color: #e84260;
}

.c-magazine-subtitle:hover::after {
  width: 4px;
  background: url('./img/read_more_arrow_hover.svg') no-repeat;
}

.c-digest-form {
  padding-top: 62px;
  display: inline-block;
  width: 100%;
}

.c-digest-form-title {
  margin-bottom: 11px;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: 1px;
  color: #121212;
}

.c-digest-form-email-container {
  display: inline-block;
  width: calc(100% - 125px);
  max-width: 218px;
}

.c-digest-form-email-container input {
  max-width: 218px;
  height: 28px;
  color: #121212;
}

.c-digest-form-email-container span:first-child {
  top: 25px !important; /* stylelint-disable-line */
}

.c-digest-form-email-container span:only-child {
  top: 0px !important; /* stylelint-disable-line */
}

.c-digest-form-email {
  padding: 3px 8px 5px;
  font-family: Lato;
  font-size: 14px;
  line-height: 1.43;
  color: var(--decorative-subway-msk-serpukhovskaya);
  background-color: white;
  border: solid 1px #c9c9c9;
  border-radius: 4px;
  outline: none;
}

.c-digest-form-email:focus {
  color: #121212;
  border: 1px solid #121212;
}

.c-digest-form-button {
  position: relative;
  top: -1px;
  margin-left: 8px;
  padding: 3px 16px 5px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  cursor: pointer;
  color: white;
  background-color: #2b87db;
  border: 0;
  border-radius: 42px;
  outline: none;
}

.c-digest-form-button:hover,
.c-digest-form-button:focus {
  background-color: #256ba5;
}

.c-digest-form-retry {
  margin-top: 8px;
  margin-bottom: 26px;
}

.c-white-news {
  margin-left: -1px;
  border-left: solid 1px #e4e4e4;
}

.c-white-news.c-white-pad {
  border-right: 0;
}

.c-white-blogs {
  width: 50%;
  border-right: solid 1px #e4e4e4;
}

.c-white-blogs:last-child {
  margin-left: -1px;
  padding-right: 23px;
  border-left: solid 1px #e4e4e4;
  border-right: 0;
}

.c-bottom-wrap {
  margin-right: 0;
  width: 66%;
  border-right: solid 1px #e4e4e4;
}
.c-white-wrap {
  display: flex;
  background: white;
  border: solid 1px #e4e4e4;
  border-bottom: 0;
}
.c-white-wrap-bottom {
  display: flex;
  flex-wrap: wrap;
  border-left: solid 1px #e4e4e4;
  border-right: solid 1px #e4e4e4;
  border-bottom: solid 1px #e4e4e4;
}
.c-digest {
  margin-right: 0;
  padding: 18px 24px 38px;
  width: 34%;
  background: url('./img/message.svg') left top no-repeat;
  background-origin: content-box;
  background-size: 50px 50px;
}

.c-digest-form-status,
.c-digest-form-info {
  margin-left: 23px;
  display: block;
  line-height: 1.29;
}

.c-digest-form-status {
  position: relative;
  font-weight: bold;
}

.c-digest-form-info {
  margin-top: 5px;
  margin-bottom: 26px;
}

.c-digest-form-status::before {
  position: absolute;
  top: 5px;
  left: -20px;
  width: 14px;
  height: 10px;
  content: '';
  background: url('./img/item_selected.svg');
}

.c-digest-form-status-fail::before {
  position: absolute;
  top: 3px;
  left: -20px;
  width: 12px;
  height: 12px;
  content: '';
  background: url('./img/item-failed.svg');
}

.c-articles-container .c-articles-item .s-articles-title-hover {
  color: #e84260;
}

@media screen and (max-width: 1024px) {
  .c-articles-first {
    width: 100%;
    height: 156px;
  }

  .c-white-articles {
    width: 50%;
  }

  .c-white-news {
    width: 50%;
  }

  .c-bottom-wrap {
    border-right: 0;
  }

  .c-articles-container {
    margin-left: 0;
    width: 100%;
  }

  .c-articles-delimiter {
    width: 0;
  }

  .c-bottom-wrap {
    width: 100%;
  }

  .c-digest {
    margin-right: 0;
    padding: 26px 24px 38px;
    width: 100%;
    border-top: solid 1px #e4e4e4;
  }

  .c-digest-form {
    padding-top: 0;
    padding-left: 70px;
    width: auto;
  }

  .c-articles-first .c-articles-title {
    font-size: 22px;
  }

  .c-digest-form-email-container input {
    max-width: none;
  }
}
