.container {
  position: absolute;
  z-index: 10;
  left: 0;
  margin-top: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  min-width: 262px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.prise-type-switch {
  margin-bottom: 16px;

  & label {
    display: flex;
    flex: 0 1 auto;

    &:first-child {
      flex: 1 1 auto;
    }

    & > span {
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
    }
  }
}
