.container {
  position: relative;
  margin: 12px 0 0;
  cursor: pointer;
}

.container + .container {
  margin-left: 8px;
}

.button {
  padding: 0 16px;
  display: flex;
  justify-content: center;
  min-width: 112px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  text-decoration: none;
  color: white;
  background: var(--accent-main-primary);
  border: none;
  border-radius: 4px;
  transition: all 0.2s;
}

.button:hover {
  background: #055de1;
}

.button:active {
  background: #004ec4;
  transition: none;
}

.button--light {
  color: var(--decorative-theme-dark);
  background: white;
}

.button--light:hover {
  color: #2b87db;
  background: #f7f7f7;
}

.button--loading {
  user-select: none;
  pointer-events: none;
  color: transparent;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloader > svg {
  display: block;
}
