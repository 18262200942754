.c-link-default {
  min-height: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  border-bottom: 1px solid transparent;
  transition:
    color 0.3s,
    border-color 0.3s,
    background 0.3s;
}

.c-link-default:hover {
  color: #256ba5;
  border-bottom-color: #b6d3ed;
}
