@import '@cian/ui-kit/typography/variables.module.css';
@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0 16px 0 5px;
  display: flex;
  min-width: 320px;
  max-width: 1376px;
}

.button {
  position: absolute;
  z-index: 5;
  top: 16px;
  padding-right: 12px;
  display: flex;
  height: 29px;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  cursor: pointer;
  color: var(--decorative-theme-white);
  align-items: center;
  background: var(--overlay-default);
  border: none;
  border-radius: 4px;
}

.button .text {
  margin-left: 8px;
}

@media (min-width: 1024px) {
  .wrapper {
    padding: 0 24px;
  }
}
