.c-specialists {
  margin-top: -3px;
}

.c-specialists-section {
  margin-bottom: 38px;
}

.c-specialists-section h3 {
  margin: 0 0 15px;
  font-size: 18px;
  line-height: 24px;
}

.c-specialists-section-info {
  margin-bottom: 27px;
}

.c-specialists-section-info:last-child {
  margin-bottom: 22px;
}

.c-specialists-title {
  margin: 0 0 16px;
  display: none;
  font-size: 28px;
  font-weight: bold;
}

.c-specialists-all {
  margin-top: -5px;
  display: inline-block;
  line-height: 20px;
}

@media screen and (max-width: 1024px) {
  .c-specialists-title {
    display: block;
  }
}
