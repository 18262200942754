.c-promo-info-block {
  position: relative;
  margin-bottom: 8px;
  height: 201px;
  background: url('./img/banner.jpg') no-repeat 50% 50%;
  background-size: cover;
}

.c-promo-info-block-content {
  padding-top: 43px;
  box-sizing: border-box;
  height: 100%;
  background-color: rgba(43, 71, 86, 0.7);
  transition: background 0.15s;
}

.c-promo-info-block:hover .c-promo-info-block-content {
  background: rgba(43, 71, 86, 0.8);
}

.c-promo-info-title,
.c-promo-info-location,
.c-promo-info-cost,
.c-promo-info-phone,
.c-promo-info-developer,
.c-promo-info-text,
.c-promo-info-button {
  display: block;
  text-align: center;
}

.c-promo-info-title,
.c-promo-info-location,
.c-promo-info-cost,
.c-promo-info-phone,
.c-promo-info-developer,
.c-promo-info-text {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  color: white;
}

.c-promo-info-title {
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.c-promo-info-location,
.c-promo-info-cost,
.c-promo-info-developer {
  font-size: 14px;
  line-height: 18px;
}

.c-promo-info-location {
  margin-bottom: 5px;
}

.c-promo-info-cost {
  margin-bottom: 14px;
}

.c-promo-info-phone {
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
}

.c-promo-info-phone:hover {
  color: white;
  border-color: transparent;
}

.c-promo-info-text {
  margin: 15px 0;
}

.c-promo-info-button {
  margin: 0 auto;
  padding: 0 16px;
  display: block;
  box-sizing: border-box;
  width: 135px;
  height: 28px;
  line-height: 26px;
  border-radius: 14px;
  transition:
    border-color 0.3s,
    background-color 0.3s;
}

.c-promo-info-button {
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.c-promo-info-button:hover {
  color: white;
  background: rgba(255, 255, 255, 0.15);
  border-color: white;
}
