.c-promo-info-block-map {
  position: relative;
  margin-bottom: 8px;
  padding: 0;
  height: 200px;
  background: url('./img/bg_map.png') no-repeat 50% 50%;
  background-size: cover;
  border: solid 1px #e4e4e4;
}

.c-promo-info-block-map-content {
  padding-top: 37px;
  box-sizing: border-box;
  height: 100%;
  /* stylelint-disable */
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.9) 35%,
    rgba(255, 255, 255, 0.9) 65%,
    rgba(255, 255, 255, 0.5));
  /* stylelint-enable */
}

.c-promo-info-map-title,
.c-promo-info-map-text {
  display: block;
  text-align: center;
  color: #121212;
}

.c-promo-info-map-title {
  margin-bottom: 7px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
}

.c-promo-info-map-text {
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 1.38;
}

.c-promo-info-map-button {
  margin: 0 auto;
  padding: 0 16px;
  display: block;
  box-sizing: border-box;
  width: 135px;
  height: 28px;
  font-weight: bold;
  line-height: 1.8;
  border-radius: 14px;
}

.c-promo-info-map-button {
  color: #2b87db;
  border: 1px solid rgba(43, 135, 219, 0.5);
}

.c-promo-info-map-button:hover {
  color: #256ba5;
  border-color: rgba(37, 107, 165, 0.5);
}
