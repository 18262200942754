.container {
  flex: 1 0 380px;
}

.dropdown {
  z-index: 10;
  margin-top: 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.input--cleanable,
input.input--cleanable {
  padding-right: 40px;
}

.input--cleanable::-ms-clear,
input.input--cleanable::-ms-clear {
  display: none;
}

.button {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: var(--decorative-subway-msk-serpukhovskaya);
  background: none;
  border: none;
  transform: translateY(-50%);
}

.button:hover {
  color: #7a7a7a;
}

.dropdown div[data-group='addresses'] span[class*='relative'] {
  padding: 4px 0;
  /* stylelint-disable-next-line */
  display: -webkit-box;
  overflow: hidden;
  line-height: 20px;
  white-space: pre-line;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
